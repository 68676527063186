import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer, Slide } from 'react-toastify';
import App from './App';
import Reset from './Reset';

import 'bootstrap/dist/css/bootstrap.min.css';

const firebaseConfig = {
  apiKey: "AIzaSyBr5xTKH7AcfixIFozKZqTJwZ4IsjWMy7A",
  authDomain: "edcom-portal.firebaseapp.com",
  projectId: "edcom-portal",
  storageBucket: "edcom-portal.appspot.com",
  messagingSenderId: "325782358831",
  appId: "1:325782358831:web:f2ba4c15238d499266efda"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);

const router = createBrowserRouter([
  { path: "/", element: <App auth={auth} functions={functions}/>, errorElement: <center style={{margin: '100px'}}>Page not found</center> },
  { path: "/reset", element: <Reset auth={auth}/> }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
    <ToastContainer position="top-center" hideProgressBar={true} draggable={false} transition={Slide} />
  </React.StrictMode>
);

reportWebVitals();
