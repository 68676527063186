import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from "firebase/auth";
import { toast } from 'react-toastify';
import { Button, Card, Dropdown, DropdownButton, Modal, Form } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { fixError } from './errors';
import SignUp from './SignUp';
import Login from './Login';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const ARTIFACT_URL = 'https://api.keygen.sh/v1/accounts/5b5914e5-3f64-439e-af80-b4404f2eb853/artifacts/edcom-install.tgz';
const ARTIFACT_URL_ARM = 'https://api.keygen.sh/v1/accounts/5b5914e5-3f64-439e-af80-b4404f2eb853/artifacts/edcom-install-arm64.tgz';
const ARTIFACT_FILE = 'edcom-install.tgz';
const ARTIFACT_FILE_ARM = 'edcom-install-arm64.tgz';
const MTA_ARTIFACT_URL = 'https://api.keygen.sh/v1/accounts/5b5914e5-3f64-439e-af80-b4404f2eb853/artifacts/velocity-install.tgz';
const MTA_ARTIFACT_URL_ARM = 'https://api.keygen.sh/v1/accounts/5b5914e5-3f64-439e-af80-b4404f2eb853/artifacts/velocity-install-arm64.tgz';
const MTA_ARTIFACT_FILE = 'velocity-install.tgz';
const MTA_ARTIFACT_FILE_ARM = 'velocity-install-arm64.tgz';

function App({auth, functions}) {
  const location = useLocation();
  const [user, userLoading, userError] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState(null);
  const [licenseStatus, setLicenseStatus] = useState('Loading license info...');
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [arch, setArch] = useState('amd64');

  const activate = (new URLSearchParams(location.search)).get('activate');

  useEffect(() => {
    if (userError) {
      toast.error(userError.message);
    }
  }, [userError]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const getLicenses = httpsCallable(functions, 'get_licenses');
    getLicenses()
      .then(result => {
        setUserInfo(result.data);
      })
      .catch(e => {
        setLicenseStatus(fixError(e.message));
      })
  }, [user, functions]);

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (e) {
      toast.error(fixError(e.message));
    }
  }

  const copyToClipboard = (text, desc) => {
    navigator.clipboard.writeText(text);
    toast.success(`${desc} copied!`);
  }

  const showSupport = e => {
    e.preventDefault();

    setShowSupportModal(true);
  }

  const hideSupport = () => {
    setShowSupportModal(false);
  }

  return (
    <div className="content">
      <Modal show={showSupportModal} onHide={hideSupport} centered>
        <Modal.Body>
          <div className="modal-body">
            <div>For support so good your head will 🤯</div>
            <div>contact us at <a href="mailto:emailteam@emaildelivery.com">emailteam@emaildelivery.com</a></div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="topbar">
        <div>
          <a href="https://emaildelivery.com" target="_blank" rel="noopener noreferrer">
            <img className="logo" src="cropped-emaildelivery-logo-e1680130388900.webp" alt="logo"/>
          </a>
          <a href="https://docs.emaildelivery.com" target="_blank" rel="noopener noreferrer" className="top-link">
            Docs
          </a>
          <a href="#support" onClick={showSupport} className="top-link">
            Support
          </a>
          <a href="https://docs.emaildelivery.com/docs/install/automated-installation" target="_blank" rel="noopener noreferrer" className="top-link">
            Easy Installation Video
          </a>
        </div>
        {
        user &&
        <div className="d-flex justify-content-end align-items-center">
          <DropdownButton variant="outline-light" title={<i className="bi bi-person-circle"/>} className="menu-button">
            <Dropdown.ItemText style={{backgroundColor: '#dfdfdf'}}>Logged in as {user.email}</Dropdown.ItemText>
            <Dropdown.Divider />
            <Dropdown.Item as="button" onClick={logout}><i className="bi bi-box-arrow-in-left"/> Logout</Dropdown.Item>
          </DropdownButton>
        </div>
        }
      </div>
      {
        (!userLoading && !user) && (
          activate ?
            <SignUp auth={auth} functions={functions} activate={activate}/>
          :
            <Login auth={auth} showSupport={showSupport}/>
        )
      }
      {
        user &&
        <div className="ms-5 me-5 mt-3 mb-3">
          {
            !userInfo ?
              <center>{licenseStatus}</center>
            :
              (
                !userInfo.licenses?.appsumo ?
                  <center>You currently have no valid licenses.</center>
                :
                <div>
                  {
                    userInfo.licenses.appsumo.ed.plan_id !== 'emaildeliverycom_tier3' &&
                    <p>
                      <Button variant="success" as="a" href={`https://appsumo.com/account/redemption/${userInfo.invoice_item_uuid}/change-plan`}>Upgrade to Postmaster Pro!</Button>
                    </p>
                  }

                  <h5 className="mt-4">License: {userInfo.licenses.appsumo.ed.plan_name}</h5>
                  <Card className="license-card">
                    <Card.Body>
                      <table className="license-table">
                        <tbody>
                          <tr>
                            <td>ESP Platform License Key:</td>
                            <td>{userInfo.licenses.appsumo.ed.key}</td>
                            <td><Button variant="outline-dark" onClick={() => copyToClipboard(userInfo.licenses.appsumo.ed.key, "Platform key")}><i className="bi bi-clipboard"></i></Button></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="mt-3 mb-1 d-flex justify-content-between align-items-center">
                        <span className="flex-grow-1">ESP Platform Download Command:</span>
                        <div className="d-flex align-items-center">
                          <Form.Select className="arch-select me-2" aria-label="Select an option" value={arch} onChange={e => setArch(e.target.value)}>
                            <option value="amd64">Intel/AMD</option>
                            <option value="arm64">ARM</option>
                          </Form.Select>
                          <Button variant="outline-dark" onClick={() => copyToClipboard(`curl -L ${arch === 'amd64' ? ARTIFACT_URL : ARTIFACT_URL_ARM} -H 'Authorization: License ${userInfo.licenses.appsumo.ed.key}' -o ${arch === 'amd64' ? ARTIFACT_FILE : ARTIFACT_FILE_ARM}`, "Command")}><i className="bi bi-clipboard"></i></Button>
                        </div>
                      </div>
                      <pre style={{backgroundColor: '#333', color: 'white', padding: '10px', textWrap: 'wrap', marginBottom: '10px'}}>
                        curl -L <a href="#link" style={{textDecoration: 'none', color: 'white', pointerEvents: 'none'}}>{arch === 'amd64' ? ARTIFACT_URL : ARTIFACT_URL_ARM}</a> -H 'Authorization: License {userInfo.licenses.appsumo.ed.key}' -o {arch === 'amd64' ? ARTIFACT_FILE : ARTIFACT_FILE_ARM}
                      </pre>
                    </Card.Body>
                  </Card>
                  <Card className="license-card mt-2">
                    <Card.Body>
                      <table className="license-table">
                        <tbody>
                          <tr>
                            <td>MTA License Key:</td>
                            <td>{userInfo.licenses.appsumo.mta.key}</td>
                            <td><Button variant="outline-dark" onClick={() => copyToClipboard(userInfo.licenses.appsumo.mta.key, "MTA key")}><i className="bi bi-clipboard"></i></Button></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="mt-3 mb-1 d-flex justify-content-between align-items-center">
                        <span>MTA Download Command:</span>           
                        <div className="d-flex align-items-center">
                          <Form.Select className="arch-select me-2" aria-label="Select an option" value={arch} onChange={e => setArch(e.target.value)}>
                            <option value="amd64">Intel/AMD</option>
                            <option value="arm64">ARM</option>
                          </Form.Select>
                          <Button variant="outline-dark" onClick={() => copyToClipboard(`curl -L ${arch === 'amd64' ? MTA_ARTIFACT_URL : MTA_ARTIFACT_URL_ARM} -H 'Authorization: License ${userInfo.licenses.appsumo.mta.key}' -o ${arch === 'amd64' ? MTA_ARTIFACT_FILE : MTA_ARTIFACT_FILE_ARM}`, "Command")}><i className="bi bi-clipboard"></i></Button>
                        </div>
                      </div>
                      <pre style={{backgroundColor: '#333', color: 'white', padding: '10px', textWrap: 'wrap', marginBottom: '10px'}}>
                        curl -L <a href="#link" style={{textDecoration: 'none', color: 'white', pointerEvents: 'none'}}>{arch === 'amd64' ? MTA_ARTIFACT_URL : MTA_ARTIFACT_URL_ARM}</a> -H 'Authorization: License {userInfo.licenses.appsumo.mta.key}' -o {arch === 'amd64' ? MTA_ARTIFACT_FILE : MTA_ARTIFACT_FILE_ARM}
                      </pre>
                    </Card.Body>
                  </Card>
                  <h5 className="mt-5">Plan Includes</h5>
                  <ul className="check-list">
                    <li>Lifetime access to support and updates</li>
                    {
                      userInfo.licenses.appsumo.ed.plan_id === 'emaildeliverycom_tier1' &&
                      <>
                        <li><b>1</b> email marketing frontend</li>
                        <li><b>1</b> backend</li>
                      </>
                    }
                    {
                      userInfo.licenses.appsumo.ed.plan_id === 'emaildeliverycom_tier2' &&
                      <>
                        <li><b>6</b> email marketing frontends</li>
                        <li><b>1</b> backend</li>
                      </>
                    }
                    {
                      userInfo.licenses.appsumo.ed.plan_id === 'emaildeliverycom_tier3' &&
                      <>
                        <li><b>Unlimited</b> email marketing frontends</li>
                        <li><b>Unlimited</b> backends</li>
                      </>
                    }
                    <li>Unlimited SMTP Relays and APIs</li>
                    <li>Unlimited domains, contacts, and sending</li>
                    <li>Velocity MTA included</li>
                    <li>Broadcast campaigns</li>
                    <li>Automated email sequences</li>
                    <li>Dynamic segmenting</li>
                    <li>Pop-ups, slide outs, floating bar, and embedded subscriber forms</li>
                    <li>Three email composers including drag and drop, WYSIWYG, and raw HTML</li>
                    <li>White label ready</li>
                    <li>Transactional email</li>
                    <li>Integrations</li>
                  </ul>
                </div>
              )
          }
        </div>
      }
      <div className="footer">
        © 2023. All Rights Reserved
      </div>
    </div>
  );
}

export default App;
