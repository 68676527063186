import { useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { signOut, sendPasswordResetEmail } from "firebase/auth";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { fixError } from './errors';

function Reset({ auth }) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [sentCode, setSentCode] = useState(false);
  const navigate = useNavigate();

  const sendCodeClicked = async e => {
    e.preventDefault();

    setDisableSubmit(true);
    try {
      await signOut(auth);

      await sendPasswordResetEmail(auth, email);

      toast.success("Password reset email sent");

      setSentCode(true);
    } catch (e) {
      toast.error(fixError(e.message));
    } finally {
      setDisableSubmit(false);
    }
  };

  const goBackClicked = async e => {
    e.preventDefault();

    navigate("/");
  };

  const emailChanged = e => {
    setEmail(e.target.value);
  }

  return (
    <div className="content">
      <div className="topbar">
        <a href="https://emaildelivery.com" target='_blank' rel="noopener noreferrer">
          <img className="logo" src="cropped-emaildelivery-logo-e1680130388900.webp" alt="logo"/>
        </a>
      </div>
      <div className="d-flex align-items-center justify-content-center" style={{ position: 'fixed', left: 0, top: 0, right: 0, bottom: 0, pointerEvents: 'none' }}>
        <Card className="login-card">
          <Card.Body>
            <Card.Title><center>Reset Password</center></Card.Title>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" value={email} onChange={emailChanged} autoFocus={true}/>
              </Form.Group>
              <div className="d-flex justify-content-between align-items-center">
                <Button variant="primary" type="submit" onClick={sentCode ? goBackClicked : sendCodeClicked} disabled={disableSubmit || !email}>
                  {
                    sentCode ? 'Return to Login' : 'Send Reset Link'
                  }
                </Button>
                {
                  sentCode &&
                  <Button variant="secondary" type="submit" onClick={sendCodeClicked} disabled={disableSubmit || !email}>Resend Reset Link</Button>
                }
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <div className="footer">
        © 2023. All Rights Reserved
      </div>
    </div>
  );
}

export default Reset;