import { useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { fixError } from './errors';

function SignUp({ auth, showSupport }) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submitClicked = async e => {
    e.preventDefault();

    if (!password) {
      toast.error("Please enter a password");
      return;
    }

    setDisableSubmit(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      toast.error(fixError(e.message));
    } finally {
      setDisableSubmit(false);
    }
  };

  const emailChanged = e => {
    setEmail(e.target.value);
  }
  const passwordChanged = e => {
    setPassword(e.target.value);
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ position: 'fixed', left: 0, top: 0, right: 0, bottom: 0, pointerEvents: 'none' }}>
      <>
        <Card className="login-card">
          <Card.Body>
            <Card.Title><center>Login</center></Card.Title>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" value={email} onChange={emailChanged} autoFocus={true}/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" value={password} onChange={passwordChanged} />
              </Form.Group>
              <div className="d-flex justify-content-between align-items-center">
                <Button variant="primary" type="submit" onClick={submitClicked} disabled={disableSubmit || !email}>Submit</Button>
                <Link to="/reset">Reset password</Link>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <Card className="help-notice">
          <Card.Body>
            <span style={{color: '#4e7ae0'}}><i className="bi bi-info-circle-fill"/></span> If you purchased with AppSumo and don't have a login, check your email for your activation link or <a href="#support" onClick={showSupport}>contact support</a>.
          </Card.Body>
        </Card>
      </>
    </div>
  );
}

export default SignUp;
