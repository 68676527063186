import { useState, useEffect } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { fixError } from './errors';

function SignUp({ functions, auth, activate }) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!activate) {
      return;
    }
    const getEmail = httpsCallable(functions, 'get_email');
    getEmail({ user_id: activate })
      .then(result => {
        setEmail(result.data.email);
      })
      .catch(e => {
        setError(fixError(e.message));
      })
  }, [activate, functions]);

  const submitClicked = async e => {
    e.preventDefault();

    if (!password) {
      toast.error("Please enter a password");
      return;
    }

    setDisableSubmit(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate("/", {replace: true});
    } catch (e) {
      if (e.message.includes('email-already-in-use')) {
        try {
          await signInWithEmailAndPassword(auth, email, password);
          navigate("/", {replace: true});
        } catch (e) {
          toast.error(fixError(e.message));
        }
      } else {
        toast.error(e.message);
      }
    } finally {
      setDisableSubmit(false);
    }
  };

  const passwordChanged = e => {
    setPassword(e.target.value);
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ position: 'fixed', left: 0, top: 0, right: 0, bottom: 0, pointerEvents: 'none' }}>
    {
      (!email && !error) ?
        <div>Loading...</div>
      :
      (
        error ?
          <div style={{backgroundColor: '#FFF', padding: '10px'}}>Error: {error}</div>
        :
          <Card className="login-card">
            <Card.Body>
              <Card.Title><center>Activate your AppSumo purchase by entering a password and clicking Submit</center></Card.Title>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" value={email} disabled={true} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Create New Password</Form.Label>
                  <Form.Control type="password" value={password} onChange={passwordChanged} autoFocus={true}/>
                </Form.Group>
                <Button variant="primary" type="submit" onClick={submitClicked} disabled={disableSubmit || !email}>Submit</Button>
              </Form>
            </Card.Body>
          </Card>
      )
    }
    </div>
  );
}

export default SignUp;
